/* ==========================================================================
Vendor
  > AOS
  https://github.com/michalsnik/aos
========================================================================== */

// Slide Right

html:not(.no-js) [data-aos='slide-right'] {
  transform: translate3d(-100px, 0, 0);
}

// Slide Left

html:not(.no-js) [data-aos='slide-left'] {
  transform: translate3d(100px, 0, 0);
}
